<template>
  <div class="text-left">
    <div class="d-flex justify-content-between">
      <h3 class="f-bold cl-primary mb-4">Notifications</h3>
    </div>

    <div class="d-flex justify-content-around" v-if="loading">
      <loader-component></loader-component>
    </div>
    <div v-if="!loading">
      <div v-if="data.length == 0">
        <p class="text-secondary">Notification is empty</p>
      </div>
      <div class="row pt-3">
        <div
          class="col-md-12 b-bottom pt-3"
          v-for="(notification, n) in data"
          :key="n"
        >
          <h6
            class="h6"
            @click.prevent="
              toDetailPackages(
                notification.code,
                notification.id,
                notification.type
              )
            "
            style="cursor: pointer"
          >
            <span class="cl-primary f-bold">{{ notification.code }}</span>
            <span class="text-secondary mr-1"> | {{ notification.title }}</span>
            <span
              class="material-icons text-danger"
              v-if="notification.is_read == '0'"
            >
              fiber_new
            </span>
          </h6>
          <p class="cl-secondary">
            {{ notification.description }}
            <a
              v-if="notification.type == 'customer-invoice-notJakarta'"
              href="https://api.whatsapp.com/send?phone=6281930456886&text=Hallo%20Mimo%20Logictics"
              target="_blank"
            >
              <span class="badge badge-success badge-lg">
                WhatsApp Admin</span
              ></a
            >
          </p>
          <div v-if="notification.type == 'customer-payment-approved'">
            <button
              class="btn btn-primary px-5 my-4 float-right"
              data-toggle="modal"
              data-target=".bd-modal-add-resi"
              v-if="notification.is_read == 0"
            >
              Submit Resi Number
            </button>
            <div
              class="modal fade bd-modal-add-resi"
              tabindex="-1"
              role="dialog"
              aria-labelledby="myLargeModalLabel"
              aria-hidden="true"
            >
              <modal-add-resi
                :package_id="notification.package_id"
                :readNotification="readNotification"
                :notif_id="notification.id"
                :getNotification="getNotification"
              ></modal-add-resi>
            </div>
          </div>
          <div
            class="w-100 table-responsive"
            v-if="
              notification.type == 'customer-invoice-isJakarta' ||
              notification.type == 'customer-invoice-notJakarta' ||
              notification.type == 'customer-invoice-additionalShippingFee'
            "
          >
            <table class="table table-bordered">
              <thead>
                <th>Description</th>
                <th>Carton</th>
                <th>Cbm</th>
                <th>Price Per CBM</th>
                <th>Total</th>
              </thead>
              <tr v-for="(inv, i) in notification.list_invoice" :key="i">
                <td>{{ inv.description }}</td>
                <td>{{ inv.carton }}</td>
                <td>{{ inv.cbm }}</td>
                <td>{{ inv.price_per_cbm }}</td>
                <td>{{ inv.total }}</td>
              </tr>
            </table>
            <button
              v-if="
                notification.is_read == 0 &&
                notification.type != 'customer-invoice-notJakarta'
              "
              class="btn btn-primary px-5 my-4 float-right"
              data-toggle="modal"
              data-target=".bd-modal-down-payment"
            >
              Payment
            </button>
            <div
              class="modal fade bd-modal-down-payment"
              tabindex="-1"
              role="dialog"
              aria-labelledby="myLargeModalLabel"
              aria-hidden="true"
            >
              <modal-down-payment
                :package_id="notification.package_id"
                :customer_id="notification.customer_id"
                :readNotification="readNotification"
                :notif_id="notification.id"
                :getNotification="getNotification"
              ></modal-down-payment>
            </div>
          </div>
          <div
            class="w-100 table-responsive"
            v-if="notification.type == 'customer-full-payment'"
          >
            <table
              class="table table-bordered"
              v-if="notification.revised_invoice.length !== 0"
            >
              <thead>
                <th>Description</th>
                <th>Carton</th>
                <th>Cbm</th>
                <th>Price Per CBM</th>
                <th>Total</th>
              </thead>
              <tr v-for="(inv, i) in notification.revised_invoice" :key="i">
                <td>{{ inv.description }}</td>
                <td>{{ inv.carton }}</td>
                <td>{{ inv.cbm }}</td>
                <td>{{ inv.price_per_cbm }}</td>
                <td>{{ inv.total }}</td>
              </tr>
            </table>
            <button
              class="btn btn-primary px-5 my-4 float-right"
              data-toggle="modal"
              data-target=".bd-modal-full-payment"
              v-if="
                notification.type == 'customer-full-payment' &&
                notification.is_read == 0
              "
            >
              Payment
            </button>
            <div
              class="modal fade bd-modal-full-payment"
              tabindex="-1"
              role="dialog"
              aria-labelledby="myLargeModalLabel"
              aria-hidden="true"
            >
              <add-payment-not-match
                :package_id="notification.package_id"
                :customer_id="notification.customer_id"
                :readNotification="readNotification"
                :notif_id="notification.id"
                :getNotification="getNotification"
              ></add-payment-not-match>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalDownPayment from "./ModalDownPayment.vue";
import AddPaymentNotMatch from "./AddPaymentNotMatch.vue";
import ModalAddResi from "./ModalAddResi.vue";
import LoaderComponent from "../../../components/Loader.vue";
export default {
  components: {
    ModalDownPayment,
    AddPaymentNotMatch,
    ModalAddResi,
    LoaderComponent,
  },
  name: "MyNotification",
  data() {
    return {
      data: [],
      loading: true,
      notif_id: "",
    };
  },

  mounted() {
    this.getNotification();
    this.scrollToTop();
  },
  async created() {
    this.data = await this.getNotification();
  },

  methods: {
    async getNotification() {
      this.loading = true;
      const endpoint = "notification/customer";
      this.$api.get(endpoint, (status, data, message) => {
        if (status === 200) {
          this.loading = false;
          this.data = data.notification;
          console.log(this.data);
          return data.notification.data;
        } else {
          this.loading = false;
          console.log(message);
          return data.notification.data;
        }
      });
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },

    toDetailPackages(code, idnotif, type) {
      this.notif_id = idnotif;
      if (
        type == "customer-notification" ||
        type == "customer-full-payment-approved"
      ) {
        this.readNotification(idnotif, code);
      }
      this.$router.push("/customers/detail-packages/" + code).then(() => {});
      console.log(code);
    },

    async readNotification(idnotif, code) {
      const endpoint = "notification/read/" + idnotif;
      this.$api.post(endpoint, (status, message) => {
        if (status == 200) {
          console.log(message);
          this.$router.push("/customers/detail-packages/" + code);
          this.data = this.getNotification();
          window.location.reload();
        }
      });
    },
  },
};
</script>
