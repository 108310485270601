<template>
  <div>
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h3
            class="modal-title cl-primary f-bold text-center"
            id="exampleModalLabel"
          >
            Upload Full Payment
          </h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitProof">
            <div class="row">
              <div class="col-md-12">
                <label for="exampleFormControlTextarea1" class="text-secondary">
                  Description</label
                >
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  v-model="form.description"
                ></textarea>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="type" class="text-secondary">Payment Type</label>
                  <select
                    name="type"
                    id="type"
                    v-model="form.type"
                    class="form-control b-radius"
                  >
                    <!-- <option value="down_payment">Down Payment</option> -->
                    <!-- <option value="additional_payment">
                      Additional Payment
                    </option> -->
                    <option value="full_payment">Full Payment</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="exampleFormControlFile1" class="text-secondary"
                    >Upload File</label
                  >
                  <input
                    type="file"
                    class="form-control-file"
                    id="exampleFormControlFile1"
                    ref="file"
                    @change="handleImageProof()"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary px-5"
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary px-sm-5"
            data-dismiss="modal"
            v-on:click="submitProof()"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
export default {
  props: [
    "package_id",
    "customer_id",
    "readNotification",
    "notif_id",
    "getNotification",
  ],
  data() {
    return {
      form: {
        package_id: this.package_id,
        customer_id: this.customer_id,
        description: "",
        type: "",
        image: "",
      },
    };
  },
  mounted() {
    console.log(this.package_id);
    console.log(this.customer_id);
  },

  methods: {
    submitProof() {
      Vue.swal({
        title: "Are you sure?",
        text: "Your proof of payment will be stored in our system",
        icon: "question",
        showCancelButton: true,
        cancelButtonColor: "#eb4d4b",
        confirmButtonText: "Yes, Submit!",
      }).then((result) => {
        if (result.isConfirmed) {
          const base_url = "https://api-dev.mimologistics.id/";
          // const base_url = "http://localhost:8000/";
          let formData = new FormData();
          this.loading = true;
          formData.append("package_id", this.form.package_id);
          formData.append("customer_id", this.form.customer_id);
          formData.append("description", this.form.description);
          formData.append("type", this.form.type);
          formData.append("image", this.form.image);
          axios({
            url: base_url + "payment-proof",
            data: formData,
            method: "POST",
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }).then((res) => {
            this.readNotification(this.notif_id);
            this.getNotification();
            console.log(res);
            Vue.swal("Success!", "Payment Proof Submitted", "success").then(
              () => {
                window.location.reload();
              }
            );
          });
        }
      });
      console.log(this.package_id);
      console.log(this.customer_id);
      console.log(this.form);
    },

    handleImageProof() {
      this.form.image = this.$refs.file.files[0];
      console.log(this.form.image);
    },
  },
};
</script>
