<template>
  <div>
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h3
            class="modal-title cl-primary f-bold text-center"
            id="exampleModalLabel"
          >
            Submit Your Resi Number
          </h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="row">
              <div class="col-md-12">
                <label for="exampleFormControlTextarea1" class="text-secondary">
                  Resi Number</label
                >
                <textarea
                  v-model="form.tracking_number"
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                ></textarea>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary px-5"
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary px-sm-5"
            data-dismiss="modal"
            v-on:click.prevent="submitResi()"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
export default {
  props: [
    "package_id",
    "customer_id",
    "readNotification",
    "notif_id",
    "getNotification",
  ],
  data() {
    return {
      form: {
        package_id: this.package_id,
        tracking_number: "",
      },
    };
  },

  mounted() {
    console.log(this.package_id);
  },

  methods: {
    submitResi() {
      Vue.swal({
        title: "Are you sure?",
        text: "Your data will be entered into our system",
        icon: "question",
        showCancelButton: true,
        cancelButtonColor: "#eb4d4b",
        confirmButtonText: "Yes, Submit Tracking Number!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          const endpoint = "customer-package/tracking-number";
          this.$api.post(endpoint, this.form, (status, data, message) => {
            if (status === 200) {
              this.loading = false;
              this.readNotif(this.notif_id);
              this.getNotification();
              Vue.swal(
                "Success",
                "Your data is already entered in our system",
                "success"
              ).then(() => {
                // this.getDetailPackage();
                window.location.reload();
              });
            } else {
              this.loading = false;
              console.log(message);
            }
          });
        }

        //   this.$store
        //     .dispatch("postTrackingNumber", this.form)
        //     .then(() => {
        //       Vue.swal(
        //         "Success",
        //         "Your data is already entered in our system",
        //         "success"
        //       ).then(() => {
        //         this.readNotification(this.notif_id);
        //         this.getNotification();
        //       });
        //     })
        //     .catch((err) => {
        //       // let errorMsg = "";
        //       // this.loading = false;
        //       // Object.keys(err.response.data).forEach(function (key) {
        //       //   let errorItem = key.charAt(0).toUpperCase() + key.slice(1);
        //       //   let errorCause = "";
        //       //   let errorLists = err.response.data[key];
        //       //   for (var prop in errorLists) {
        //       //     errorCause += "<li>" + errorLists[prop] + "</li>";
        //       //   }
        //       //   errorMsg +=
        //       //     "<ul style='list-style: none; text-align: left;' class='text-left'><b>" +
        //       //     errorItem +
        //       //     "</b>" +
        //       //     errorCause +
        //       //     "</ul><br>";
        //       // });
        //       // Vue.swal({
        //       //   icon: "error",
        //       //   title: "Error!",
        //       //   html: errorMsg,
        //       // });
        //       console.log(err);
        //     });
        // }
      });
      console.log(this.form);
    },
    readNotif(idnotif, code) {
      const endpoint = "notification/read/" + idnotif;
      this.$api.post(endpoint, (status, message) => {
        if (status == 200) {
          console.log(message);
          window.location.reload();
          this.$router.push("/customers/detail-packages/" + code);
          this.data = this.getNotification();
        }
      });
    },
  },
};
</script>
