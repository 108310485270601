var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-left"},[_vm._m(0),(_vm.loading)?_c('div',{staticClass:"d-flex justify-content-around"},[_c('loader-component')],1):_vm._e(),(!_vm.loading)?_c('div',[(_vm.data.length == 0)?_c('div',[_c('p',{staticClass:"text-secondary"},[_vm._v("Notification is empty")])]):_vm._e(),_c('div',{staticClass:"row pt-3"},_vm._l((_vm.data),function(notification,n){return _c('div',{key:n,staticClass:"col-md-12 b-bottom pt-3"},[_c('h6',{staticClass:"h6",staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.preventDefault();return _vm.toDetailPackages(
              notification.code,
              notification.id,
              notification.type
            )}}},[_c('span',{staticClass:"cl-primary f-bold"},[_vm._v(_vm._s(notification.code))]),_c('span',{staticClass:"text-secondary mr-1"},[_vm._v(" | "+_vm._s(notification.title))]),(notification.is_read == '0')?_c('span',{staticClass:"material-icons text-danger"},[_vm._v(" fiber_new ")]):_vm._e()]),_c('p',{staticClass:"cl-secondary"},[_vm._v(" "+_vm._s(notification.description)+" "),(notification.type == 'customer-invoice-notJakarta')?_c('a',{attrs:{"href":"https://api.whatsapp.com/send?phone=6281930456886&text=Hallo%20Mimo%20Logictics","target":"_blank"}},[_c('span',{staticClass:"badge badge-success badge-lg"},[_vm._v(" WhatsApp Admin")])]):_vm._e()]),(notification.type == 'customer-payment-approved')?_c('div',[(notification.is_read == 0)?_c('button',{staticClass:"btn btn-primary px-5 my-4 float-right",attrs:{"data-toggle":"modal","data-target":".bd-modal-add-resi"}},[_vm._v(" Submit Resi Number ")]):_vm._e(),_c('div',{staticClass:"modal fade bd-modal-add-resi",attrs:{"tabindex":"-1","role":"dialog","aria-labelledby":"myLargeModalLabel","aria-hidden":"true"}},[_c('modal-add-resi',{attrs:{"package_id":notification.package_id,"readNotification":_vm.readNotification,"notif_id":notification.id,"getNotification":_vm.getNotification}})],1)]):_vm._e(),(
            notification.type == 'customer-invoice-isJakarta' ||
            notification.type == 'customer-invoice-notJakarta' ||
            notification.type == 'customer-invoice-additionalShippingFee'
          )?_c('div',{staticClass:"w-100 table-responsive"},[_c('table',{staticClass:"table table-bordered"},[_vm._m(1,true),_vm._l((notification.list_invoice),function(inv,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(inv.description))]),_c('td',[_vm._v(_vm._s(inv.carton))]),_c('td',[_vm._v(_vm._s(inv.cbm))]),_c('td',[_vm._v(_vm._s(inv.price_per_cbm))]),_c('td',[_vm._v(_vm._s(inv.total))])])})],2),(
              notification.is_read == 0 &&
              notification.type != 'customer-invoice-notJakarta'
            )?_c('button',{staticClass:"btn btn-primary px-5 my-4 float-right",attrs:{"data-toggle":"modal","data-target":".bd-modal-down-payment"}},[_vm._v(" Payment ")]):_vm._e(),_c('div',{staticClass:"modal fade bd-modal-down-payment",attrs:{"tabindex":"-1","role":"dialog","aria-labelledby":"myLargeModalLabel","aria-hidden":"true"}},[_c('modal-down-payment',{attrs:{"package_id":notification.package_id,"customer_id":notification.customer_id,"readNotification":_vm.readNotification,"notif_id":notification.id,"getNotification":_vm.getNotification}})],1)]):_vm._e(),(notification.type == 'customer-full-payment')?_c('div',{staticClass:"w-100 table-responsive"},[(notification.revised_invoice.length !== 0)?_c('table',{staticClass:"table table-bordered"},[_vm._m(2,true),_vm._l((notification.revised_invoice),function(inv,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(inv.description))]),_c('td',[_vm._v(_vm._s(inv.carton))]),_c('td',[_vm._v(_vm._s(inv.cbm))]),_c('td',[_vm._v(_vm._s(inv.price_per_cbm))]),_c('td',[_vm._v(_vm._s(inv.total))])])})],2):_vm._e(),(
              notification.type == 'customer-full-payment' &&
              notification.is_read == 0
            )?_c('button',{staticClass:"btn btn-primary px-5 my-4 float-right",attrs:{"data-toggle":"modal","data-target":".bd-modal-full-payment"}},[_vm._v(" Payment ")]):_vm._e(),_c('div',{staticClass:"modal fade bd-modal-full-payment",attrs:{"tabindex":"-1","role":"dialog","aria-labelledby":"myLargeModalLabel","aria-hidden":"true"}},[_c('add-payment-not-match',{attrs:{"package_id":notification.package_id,"customer_id":notification.customer_id,"readNotification":_vm.readNotification,"notif_id":notification.id,"getNotification":_vm.getNotification}})],1)]):_vm._e()])}),0)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-content-between"},[_c('h3',{staticClass:"f-bold cl-primary mb-4"},[_vm._v("Notifications")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('th',[_vm._v("Description")]),_c('th',[_vm._v("Carton")]),_c('th',[_vm._v("Cbm")]),_c('th',[_vm._v("Price Per CBM")]),_c('th',[_vm._v("Total")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('th',[_vm._v("Description")]),_c('th',[_vm._v("Carton")]),_c('th',[_vm._v("Cbm")]),_c('th',[_vm._v("Price Per CBM")]),_c('th',[_vm._v("Total")])])}]

export { render, staticRenderFns }